.blocker {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 99999;
  opacity: 0.5;
  z-index: 1000;

  .loading {
    position: absolute;
    width: 100%;
    top: 150px;
    text-align: center;
    z-index: 1001;

    img {
      width: 100px;
    }

    .message {
      display: block;
      margin-top: 10px;
      font-size: 1.5rem;
      font-stretch: expanded;
      text-transform: uppercase;
      letter-spacing: 0.2em;
    }
  }
}

.blocker,
.blocker * {
  cursor: wait !important;
}