@import 'src/styles/responsive';

.process-header {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
}

iframe.asset-viewer {
    border-radius: .25rem;
    outline: solid 1px #0000002d;
}

.questionnaire {
    .element {
        margin-bottom: 1.5rem;
    }
}

.file-explorer {
    // padding-bottom: 1.5rem !important;

    .selected {
        border: dotted 1px #0d6efd;
    }
}

.metadata {
    font-size: 90%;

    label {
        margin-top: 1.5rem;
        font-weight: bold;
        // text-transform: uppercase;
        // font-size: 80%;
        color: gray;
    }
}

.folder-navigation {
    .breadcrumb {
        margin-top: .5rem;
        margin-bottom: .5rem;
    }
}

.note {
    background-color: #fdfdfd;
    border: solid 1px lightgray;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: .25rem;

    .author {
        font-size: .85rem;
        color: #777;
        margin-bottom: .5rem;

        .avatar {
            position: relative;
            top: 6px;
        }
    }

    .text {
        margin-top: .5rem;
        font-size: 1rem;
    }
}

.note.resolved {
    background-color: #f0f0f0;
    border: solid 1px #c0c0c0;
}