.uploads {
    display: flex;
    flex-wrap: wrap;
    margin-left: calc(var(--bs-gutter-x) * .5);
    margin-right: calc(var(--bs-gutter-x) * .5);
    margin-bottom: calc(var(--bs-gutter-x) * .5);
}

.uploads > * {
    padding-left: calc(var(--bs-gutter-x) * .5);
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-bottom: calc(var(--bs-gutter-x) * .5);
    text-align: center;

    .upload-image {
        max-width: 125px;
        max-height: 125px;
    }

    .file-name {
        font-size: 85%;
    }    
}