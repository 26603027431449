/* #region media queries */

$xxs-max-width: 479px;
$xs-min-width: 480px;

$xs-max-width: 575px;
$sm-min-width: 576px;

$sm-max-width: 767px;
$md-min-width: 768px;

$md-max-width: 991px;
$lg-min-width: 992px;

$lg-max-width: 1199px;
$xl-min-width: 1200px;

$xl-max-width: 1399px;
$xxl-min-width: 1400px;

// exact sizes

@mixin xxs() {
    @media (max-width: $xxs-max-width) {
        @content;
    }
}

@mixin xs() {
    @media (min-width: $xs-min-width) and (max-width: $xs-max-width) {
        @content;
    }
}

@mixin sm() {
    @media (min-width: $sm-min-width) and (max-width: $sm-max-width) {
        @content;
    }
}

@mixin md() {
    @media (min-width: $md-min-width) and (max-width: $md-max-width) {
        @content;
    }
}

@mixin lg() {
    @media (min-width: $lg-min-width) and (max-width: $lg-max-width) {
        @content;
    }
}

@mixin xl() {
    @media (min-width: $xl-min-width) and (max-width: $xl-max-width) {
        @content;
    }
}

@mixin xxl() {
    @media (min-width: $xxl-min-width) {
        @content;
    }
}

// below sizes

@mixin xs-below() {
    @media (max-width: $xs-max-width) {
        @content;
    }
}

@mixin sm-below() {
    @media (max-width: $sm-max-width) {
        @content;
    }
}

@mixin md-below() {
    @media (max-width: $md-max-width) {
        @content;
    }
}

@mixin lg-below() {
    @media (max-width: $lg-max-width) {
        @content;
    }
}

@mixin xl-below() {
    @media (max-width: $xl-max-width) {
        @content;
    }
}

@mixin xxl-below() {
    @media (max-width: $xxl-min-width) {
        @content;
    }
}

// above sizes

@mixin xxs-above() {
    @media (min-width: $xxs-min-width) {
        @content;
    }
}

@mixin xs-above() {
    @media (min-width: $xs-min-width) {
        @content;
    }
}

@mixin sm-above() {
    @media (min-width: $sm-min-width) {
        @content;
    }
}

@mixin md-above() {
    @media (min-width: $md-min-width) {
        @content;
    }
}

@mixin lg-above() {
    @media (min-width: $lg-min-width) {
        @content;
    }
}

@mixin xl-above() {
    @media (min-width: $xl-min-width) {
        @content;
    }
}

/* #endregion */

// Bootstrap applies responsive styles to the specified breakpoint and above.
// Below, we are applying styles to the specified breakpoint and below.

/* #region display: none */

@include xxs() {
    .xxs-none {
        display: none !important;
    }
}

@include xs-below() {
    .xs-none {
        display: none !important;
    }
}

@include sm-below() {
    .sm-none {
        display: none !important;
    }
}

@include md-below() {
    .md-none {
        display: none !important;
    }
}

@include lg-below() {
    .lg-none {
        display: none !important;
    }
}

@include xl-below() {
    .xl-none {
        display: none !important;
    }
}

/* #endregion */

/* #region spacing */

$spacers: (0.25rem, 0.5rem, 1rem, 1.5rem, 3rem);

@include xxs() {
    @for $i from 1 through length($spacers) {
        $value: nth($spacers, $i);

        .xxs-my-#{$i} {
            margin-top: $value !important;
            margin-bottom: $value !important;
        }

        .xxs-mt-#{$i} {
            margin-top: $value !important;
        }

        .xxs-mb-#{$i} {
            margin-bottom: $value !important;
        }

        .xxs-mx-#{$i} {
            margin-left: $value !important;
            margin-right: $value !important;
        }

        .xxs-mr-#{$i} {
            margin-right: $value !important;
        }

        .xxs-ml-#{$i} {
            margin-left: $value !important;
        }

        .xxs-py-#{$i} {
            padding-top: $value !important;
            padding-bottom: $value !important;
        }

        .xxs-pt-#{$i} {
            padding-top: $value !important;
        }

        .xxs-pb-#{$i} {
            padding-bottom: $value !important;
        }

        .xxs-px-#{$i} {
            padding-left: $value !important;
            padding-right: $value !important;
        }

        .xxs-pr-#{$i} {
            padding-right: $value !important;
        }

        .xxs-pl-#{$i} {
            padding-left: $value !important;
        }
    }
}

@include xs-below() {
    @for $i from 1 through length($spacers) {
        $value: nth($spacers, $i);

        .xs-my-#{$i} {
            margin-top: $value !important;
            margin-bottom: $value !important;
        }

        .xs-mt-#{$i} {
            margin-top: $value !important;
        }

        .xs-mb-#{$i} {
            margin-bottom: $value !important;
        }

        .xs-mx-#{$i} {
            margin-left: $value !important;
            margin-right: $value !important;
        }

        .xs-mr-#{$i} {
            margin-right: $value !important;
        }

        .xs-ml-#{$i} {
            margin-left: $value !important;
        }

        .xs-py-#{$i} {
            padding-top: $value !important;
            padding-bottom: $value !important;
        }

        .xs-pt-#{$i} {
            padding-top: $value !important;
        }

        .xs-pb-#{$i} {
            padding-bottom: $value !important;
        }

        .xs-px-#{$i} {
            padding-left: $value !important;
            padding-right: $value !important;
        }

        .xs-pr-#{$i} {
            padding-right: $value !important;
        }

        .xs-pl-#{$i} {
            padding-left: $value !important;
        }
    }
}

@include sm-below() {
    @for $i from 1 through length($spacers) {
        $value: nth($spacers, $i);

        .sm-my-#{$i} {
            margin-top: $value !important;
            margin-bottom: $value !important;
        }

        .sm-mt-#{$i} {
            margin-top: $value !important;
        }

        .sm-mb-#{$i} {
            margin-bottom: $value !important;
        }

        .sm-mx-#{$i} {
            margin-left: $value !important;
            margin-right: $value !important;
        }

        .sm-mr-#{$i} {
            margin-right: $value !important;
        }

        .sm-ml-#{$i} {
            margin-left: $value !important;
        }

        .sm-py-#{$i} {
            padding-top: $value !important;
            padding-bottom: $value !important;
        }

        .sm-pt-#{$i} {
            padding-top: $value !important;
        }

        .sm-pb-#{$i} {
            padding-bottom: $value !important;
        }

        .sm-px-#{$i} {
            padding-left: $value !important;
            padding-right: $value !important;
        }
        
        .sm-pr-#{$i} {
            padding-right: $value !important;
        }

        .sm-pl-#{$i} {
            padding-left: $value !important;
        }
    }
}

@include md-below() {
    @for $i from 1 through length($spacers) {
        $value: nth($spacers, $i);

        .md-my-#{$i} {
            margin-top: $value !important;
            margin-bottom: $value !important;
        }

        .md-mt-#{$i} {
            margin-top: $value !important;
        }

        .md-mb-#{$i} {
            margin-bottom: $value !important;
        }

        .md-mx-#{$i} {
            margin-left: $value !important;
            margin-right: $value !important;
        }

        .md-mr-#{$i} {
            margin-right: $value !important;
        }

        .md-ml-#{$i} {
            margin-left: $value !important;
        }

        .md-py-#{$i} {
            padding-top: $value !important;
            padding-bottom: $value !important;
        }

        .md-pt-#{$i} {
            padding-top: $value !important;
        }

        .md-pb-#{$i} {
            padding-bottom: $value !important;
        }

        .md-px-#{$i} {
            padding-left: $value !important;
            padding-right: $value !important;
        }

        .md-pr-#{$i} {
            padding-right: $value !important;
        }

        .md-pl-#{$i} {
            padding-left: $value !important;
        }


    }
}

@include lg-below() {
    @for $i from 1 through length($spacers) {
        $value: nth($spacers, $i);

        .lg-my-#{$i} {
            margin-top: $value !important;
            margin-bottom: $value !important;
        }

        .lg-mt-#{$i} {
            margin-top: $value !important;
        }

        .lg-mb-#{$i} {
            margin-bottom: $value !important;
        }

        .lg-mx-#{$i} {
            margin-left: $value !important;
            margin-right: $value !important;
        }

        .lg-mr-#{$i} {
            margin-right: $value !important;
        }

        .lg-ml-#{$i} {
            margin-left: $value !important;
        }

        .lg-py-#{$i} {
            padding-top: $value !important;
            padding-bottom: $value !important;
        }

        .lg-pt-#{$i} {
            padding-top: $value !important;
        }

        .lg-pb-#{$i} {
            padding-bottom: $value !important;
        }

        .lg-px-#{$i} {
            padding-left: $value !important;
            padding-right: $value !important;
        }

        .lg-pr-#{$i} {
            padding-right: $value !important;
        }

        .lg-pl-#{$i} {
            padding-left: $value !important;
        }
    }
}

@include xl-below() {
    @for $i from 1 through length($spacers) {
        $value: nth($spacers, $i);

        .xl-my-#{$i} {
            margin-top: $value !important;
            margin-bottom: $value !important;
        }

        .xl-mt-#{$i} {
            margin-top: $value !important;
        }

        .xl-mb-#{$i} {
            margin-bottom: $value !important;
        }

        .xl-mx-#{$i} {
            margin-left: $value !important;
            margin-right: $value !important;
        }

        .xl-mr-#{$i} {
            margin-right: $value !important;
        }

        .xl-ml-#{$i} {
            margin-left: $value !important;
        }

        .xl-py-#{$i} {
            padding-top: $value !important;
            padding-bottom: $value !important;
        }

        .xl-pt-#{$i} {
            padding-top: $value !important;
        }

        .xl-pb-#{$i} {
            padding-bottom: $value !important;
        }

        .xl-px-#{$i} {
            padding-left: $value !important;
            padding-right: $value !important;
        }

        .xl-pr-#{$i} {
            padding-right: $value !important;
        }

        .xl-pl-#{$i} {
            padding-left: $value !important;
        }
    }
}

/* #endregion */