@import 'src/styles/responsive.scss';

.stepper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 0 auto;

    .step {
        flex-basis: 300px;
        flex-shrink: 1;
        position: relative;
        padding: .75rem;

        .step-circle {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            border: solid 2px #FFF;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
            font-weight: bold;
        }

        .step-title {
            margin-top: 1rem;
            font-size: 1.2rem;
            font-weight: 600;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 1rem;
            white-space: nowrap;
            color: lightgray;
        }

        .step-description {
            text-align: center;
            color: lightgray;
            font-size: 85%;
        }

        .step-status {
            text-align: center;
        }

        .step-bar-left,
        .step-bar-right {
            position: absolute;
            top: 33px;
            height: 1px;
            border-top: 1px solid #DDDDDD;

            &.step-bar-right {
                right: 0;
                left: 50%;
                margin-left: 20px;
            }

            &.step-bar-left {
                left: 0;
                right: 50%;
                margin-right: 20px;
            }
        }

        &:first-child .step-bar-left,
        &:last-child .step-bar-right {
            display: none;
        }
    }

    .skipped-step {
        .step-circle {
            background-color: #aaa;
            border-color: #aaa;
            color: #000;
        }

        .step-title {
            color: #999;
        }

        .step-description {
            color: #999;
        }
    }

    .done-step {
        .step-circle {
            background-color: #aaa;
            border-color: #aaa;
            color: #000;
        }

        .step-title {
            color: #999;
        }

        .step-description {
            color: #999;
        }
    }

    .active-step {
        .step-circle {
            background-color: #0d6efd;
            border-color: #0d6efd;
            color: #FFF;
        }

        .step-title {
            color: #FFF;
        }

        .step-description {
            color: #FFF;
        }
    }

    .pending-step {
        .step-circle {
            border-color: lightgray;
        }

        .step-title {
            color: lightgray;
        }

        .step-description {
            color: lightgray;
        }
    }
}

@include sm-below {
    .stepper {
        justify-content: left;
    }
}