.element-library {
    margin: -.75rem 0;

    button {
        width: 100%;
        border: solid 1px gray;
        border-radius: .25rem;
        text-align: left;
        padding: .5rem .75rem;
        white-space: nowrap;
        background: rgba(33, 37, 41, 0.03);
        font-size: 1rem;

        i {
            float: right;
            margin-top: .25rem;
            margin-left: .25rem;
        }
    }

    button:hover {
        background: transparent;
    }

    .user-entry {
        font-weight: bold;
    }
}

.question-set-viewer {
    border: solid 1px lightgray;
    border-radius: .25rem;
    box-shadow: 2px 1px 3px rgba(80, 80, 80, 0.3);
    min-height: 800px;
    max-height: 800px;
    overflow-y: scroll;
    padding: 15px 0 15px 15px; // offsets the scrollbar

    .element-wrapper {
        position: relative;
        padding: .75rem;
        margin: .25rem;
        border: solid 1px transparent;
    
        .element-toolbar {
            box-shadow: 3px 2px 6px rgba(80, 80, 80, 0.3);
            position: absolute;
            top: 1rem;
            right: 1rem;
            display: none;
            z-index: 2;
            background: white;
    
            button {
                padding-top: .4rem;
            }
        }
    
        .element {}
    }
    
    .element-wrapper:hover:not(.dragged):not(.dropzone) {
        border: dashed 1px lightgray;
        background: #FEFEFE;
    
        .element-toolbar {
            display: block;
        }
    }
}