@import 'src/styles/responsive';

.reset-password-wrapper { 
    margin: auto;
    max-width: 600px;

    .logo {
        text-align: center;
        font-size: 350%;
        margin-top: 5rem;
        margin-bottom: 1rem;
    }
}

@include sm-below() {
    .reset-password-wrapper {
       .logo {
            font-size: 3rem;
        }
    }
}