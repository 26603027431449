@import '/src/styles/responsive.scss';

.page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 1400px;
    margin: 0 auto;
}

header {
    min-height: 10vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 2rem;
    border-bottom: solid 1px lightgray;

    .logo {
        font-size: 3rem;
    }

    .admin-badge {
        font-weight: bold;
        color: goldenrod
    }
}

main {
    padding: 4rem 2rem;
    position: relative;
}

@include xs-below {
    header {
        padding: .5rem .5rem .5rem 1rem;

        .logo {
            font-size: 2.5rem;
        }

        .admin-badge {
            font-size: .75rem;
            display: block;
            margin-top: -.85rem;
            text-align: right;
        }
    }

    main {
        padding: 2rem 1rem;
    }
}