@import 'src/styles/responsive';

.accept-invite-wrapper { 
    margin: auto;
    max-width: 600px;

    .logo {
        text-align: center;
        font-size: 350%;
        margin-top: 5rem;
        margin-bottom: 1rem;
    }
}

@include sm-below() {
    .accept-invite-wrapper {
       padding: 1rem;

       .logo {
            font-size: 3rem;
        }
    }
}