@import 'src/styles/responsive';

.intake-form {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 1000px;
    margin: 0 auto;

    .logo {
        text-align: center;
        font-size: 400%;
        margin-top: 2rem;
        margin-bottom: 2rem;
    }

    h1 {
        margin-bottom: 1rem;
    }

    h3 {
        margin-top: 2rem;
    }

    h4 {
        margin-top: 1rem;
    }
}

@include md() {
    .intake-form {
        padding: 2rem;
    }
}

@include sm-below() {
    .intake-form {
        padding: 1rem;

        .logo {
            margin-top: 1rem;
            font-size: 3rem;
        }
    }
}