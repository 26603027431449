@import 'variables';
@import 'responsive';

* {
    box-sizing: border-box;
    font-family: 'Oswald-ExtraLight';
    letter-spacing: .05rem;
}

*::-webkit-scrollbar {
    width: 15px;
  }

  *::-webkit-scrollbar-track {
    background-color: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #76747480;
    border-radius: 1.25rem;
    border: 5px solid transparent;
    background-clip: content-box;
  }

  *::-webkit-scrollbar-thumb:hover {
    background-color: #777;
  }

  *::-webkit-scrollbar-button {
    display: none;
  }

body {
    margin: 0;
    padding: 0;
    font-size: 18px;
    color: #282c34;
}

h1 {
  margin-bottom: 1.5rem;
}

.btn:not(.btn-link),
.btn:not(.btn-link) *:not(i) {
    font-family: 'DIN-Next-W10-Light';
    text-transform: uppercase;
}

.btn {
  padding-top: 0.5rem;
  padding-bottom: 0.25rem;
}

.text-white .btn-close,
.btn-close-white {
  filter: invert(1);
}

.btn.active {
  background-color: #0d6efd;
  color: #FFF;
}

.btn.active:hover {
  background-color: #0a58ca;
  color: #FFF;
}

.btn-outline-muted {
  --bs-btn-color: #AAA;
  --bs-btn-border-color: #AAA;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #AAA;
  --bs-btn-hover-border-color: #AAA;
  --bs-btn-focus-shadow-rgb: 108,117,125;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #AAA;
  --bs-btn-active-border-color: #AAA;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #AAA;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #AAA;
  --bs-gradient: none;
}

.btn-group-xs > .btn,
.btn-xs {
  --bs-btn-padding-y: 0.15rem;
  --bs-btn-padding-x: 0.35rem;
  --bs-btn-font-size: 0.65rem;
  --bs-btn-border-radius: var(--bs-border-radius-sm);
}

.text-sm {
  font-size: 85% !important;
}

.text-xs {
  font-size: 65% !important;
}

.input-group {
  border: 1px solid transparent;
  border-radius: 0.25rem; /* Adjust as needed */
}

.input-group .btn {
  border-color: #dee2e6;
}

.input-group:focus-within {
  border-color: #80bdff;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.input-group .form-control:focus {
  box-shadow: none;
}

.input-group:focus-within .btn {
  border-color: transparent;
}

input[type="checkbox"],
input[type="radio"] {
  margin-right: .5rem;
}

a,
.btn-link {
  text-decoration: none;
}

a.link {
  cursor: pointer;
}

.clickable {
  color: #0d6efd;
  cursor: pointer !important;
}

.clickable:hover {
  color: inherit;
}

.text-gray {
  color: #a0a0a0;
}

// a:hover,
// button.btn-link:hover {
//   text-decoration: underline;
// }

.form-group {
    margin-bottom: 1rem;
}

.form-group > label {
    display: block;
    margin-bottom: .25rem;
}

.form-group > label > span {
  color: gray;
  font-size: 0.9rem;
  margin-left: .5rem;
}
.nav-tabs {
  white-space: nowrap;
  display: flex;
  flex-wrap: nowrap;
  border-bottom: none;
  position: relative;
  top: 1px;
  z-index: 1;
}

.nav-item {
  flex: 0 0 auto;
}

.nav-link {
  white-space: nowrap;
}

.nav-link .badge {
  position: relative;
  top: -4px;
  padding: 4px 6px;
  font-size: 10px;
  font-variant-numeric: tabular-nums;
}

.tab-pane {
  margin-top: 1px;
  border-top: solid 1px #dee2e6;
  position: relative;
  padding: 2rem 1rem;
}

.box {
  padding:.5rem;
  border: 1px solid #ccc;
  border-radius: .25rem;
  margin-bottom: 1rem;
  transition: opacity 0.5s ease-out;
  max-width: 100%;
}

.box-wrapper {
  padding: 1rem;
  border: 1px solid #999;
  border-radius: .25rem;
  width: 100%;
  overflow: scroll;
}

.box-wrapper::-webkit-scrollbar {
  display: none;
}

.scroll-box {
  overflow-y: scroll;
  padding: 1rem;
  border: 1px solid #999;
}

.inverse {
  background-color: #282c34;
  color: #fff;
}

.accordion-button:focus {
  outline: none;
  box-shadow: none;
}

.offcanvas.offcanvas-end-lg {
  width: 500px;
}

.offcanvas.offcanvas-end-xl {
  width: 700px;
}

.offcanvas-body {
  display: flex;
  flex-direction: column;
}

// These were supposed to be set to the same variables as the modal footer, but variables were not working
.offcanvas-bottom {
  padding: .75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc( 0.5rem - ( 1px ));
  border-bottom-left-radius: calc( 0.5rem - ( 1px ));
  margin-top: auto;
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}

.offcanvas-bottom > * {
  margin: 0;
  margin-inline-start: .25rem;
  margin-inline-end: .25rem;
}

.dropdown-toggle::after {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.dropdown-menu {
  box-shadow: 2px 2px 3px rgba(80, 80, 80, 0.3);
}

@include sm-below() {
  .dropdown-menu {
    min-width: 100%;
  }

  .dropdown-item {
    line-height: 200%;
  }
}

.non-button {
  text-transform: none !important;
}

.logo {
  font-family: 'Brandon-Grot-W05-Light';
  transition: var(--trans, color .4s ease 0s);
  white-space: nowrap;
  text-decoration: none;
  color: black;
}

.modal-sm {
  max-width: 425px;
}

.modal-xxl {
  max-width: 1500px;
}

img.avatar {
  display: inline-block;
  border-radius: 50%;
  margin-right: .5rem;
}

.fa, .fas {
  font-family: 'Font Awesome 5 Free' !important;
}

.alert-info,
.alert-danger,
.alert-warning,
.alert-success {
  background: none;
}

.alert-info {
  border-color: #055160;
}

.alert-danger {
  border-color: #58151c;
}

.alert-warning {
  border-color: #664d03;
}

.alert-success {
  border-color: #0a3622;
}

.mid-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.nowrap-headers th {
  white-space: nowrap;
}

.nowrap-columns td {
  white-space: nowrap;
}

.wait,
.wait * {
  cursor: wait !important;
}

// drag and drop

.draggable {
  cursor: grab !important;
}

.drag-handle {
  cursor: grabbing !important;
}

.dragging {
  opacity: 0.5;
  background-color: #eee !important;
  z-index: 100;
  cursor: move !important;
}

.dropzone {
  border: 1px dashed #007bff !important;
  background-color: #ddeeff !important;
}

#dialog {
  .modal-backdrop {
    z-index: 1055;
  }

  .modal-dialog {
    position: relative;
    z-index: 1056;
    margin-top: 6rem;
  }

  .modal-body {
    padding: 1.25rem 1.5rem .75rem;

    .form-group:last-child {
      margin-bottom: 0;
    }
  }

  .modal-footer {
    border-top: none;
    background: transparent !important;
  }
}

@include sm-below() {
  .modal-backdrop {
    background: #222 !important;
    opacity: 1 !important;
  }
  .modal {
    margin: 0 !important;
    padding: 0 !important;
    .modal-dialog {
      margin: 0 !important;
      .modal-content {
        border: none !important;
        box-shadow: none !important;
        border-radius: 0 !important;
      }
      .modal-header {
        position: sticky;
        top: 0;
        z-index: 10700;
        width: 100%;
        border-radius: 0 !important;
      }
    }
  }
}