.material-wrapper {
    position: relative;
    padding: .75rem;
    margin: .25rem;

    .material-toolbar {
        box-shadow: 3px 2px 6px rgba(80, 80, 80, 0.3);
        position: absolute;
        top: 1rem;
        right: 1rem;
        display: none;
        z-index: 2;
        background: white;

        button {
            padding-top: .4rem;
        }
    }
}

.material-wrapper:hover:not(.dragged):not(.dropzone) {
    border: dashed 1px lightgray;
    background: #FEFEFE;

    .material-toolbar {
        display: block;
    }
}