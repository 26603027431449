.element-library {
    margin: -.75rem 0;

    button {
        width: 100%;
        border: solid 1px gray;
        border-radius: .25rem;
        text-align: left;
        padding: .5rem .75rem;
        white-space: nowrap;
        background: rgba(33, 37, 41, 0.03);
        font-size: 1rem;

        i {
            float: right;
            margin-top: .25rem;
            margin-left: .25rem;
        }
    }

    button:hover {
        background: transparent;
    }
}

.area-toolbar {
    display: none;   
}

.area-title:hover {
    position: relative;

    .area-toolbar {
        display: block;
        position: absolute;
        top: 2rem;
        left: 1rem;
        z-index: 2;
        background: white;
    }    
}

.material-sheet-edit-viewer {
    border: solid 1px lightgray;
    border-radius: .25rem;
    box-shadow: 2px 1px 3px rgba(80, 80, 80, 0.3);
    min-height: 800px;
    max-height: 800px;
    overflow-y: scroll;
    padding: 15px 0 15px 15px; // offsets the scrollbar
}