.hamburger-menu .dropdown-toggle::after {
    display: none !important;
}

.hamburger-menu .dropdown-header {
    text-transform: uppercase;
    font-weight: bold;
    background-color: gray;
    color: #fff;
    margin-top: -8px;
    margin-bottom: 8px;
    text-align: center;
}

.hamburger-menu .active {
    background-color: #eee !important;
    color: black !important;
}

.hamburger-menu .dropdown-menu i {
    margin-right: .5rem;
}